import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";

import ContactUs from "@/components/Contacts/ContactUs.vue";
import CommercialService from "@/components/Service/CommercialService.vue";
import EvService from "@/components/Service/EvService.vue";
import ResidentialService from "@/components/Service/ResidentialService.vue";
import OurServices from "@/components/Service/OurServices.vue";
import CalendlyWidget from "@/components/Calendar/CalendlyWidget.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: ContactUs,
  },
  {
    path: "/residentialservice",
    name: "residentialservice",
    component: ResidentialService,
  },
  {
    path: "/evservice",
    name: "evservice",
    component: EvService,
  },
  {
    path: "/commercial",
    name: "commercial",
    component: CommercialService,
  },
  {
    path: "/ourservices",
    name: "ourservices",
    component: OurServices,
  },
  {
    path: "/booking",
    name: "booking",
    component: CalendlyWidget,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // For navigation like back/forward buttons
    } else {
      return { top: 0 }; // Scroll to the top for new pages
    }
  },
});

export default router;
