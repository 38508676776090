<template>
  <section class="py-16">
    <div class="container mx-auto text-center">
      <h2 class="text-4xl font-bold mb-4 text-my-gray">Our Trusted Partners</h2>
      <p class="text-my-gray mb-8 pb-7">
        We are excited to collaborate with these esteemed organizations to
        deliver exceptional services to our clients.
      </p>

      <!-- Infinite scrolling container -->
      <div
        class="relative overflow-hidden"
        data-aos="fade-left"
        data-aos-duration="3000"
      >
        <div class="logos-slider flex animate-slide">
          <!-- Duplicate logos for seamless infinite scroll -->
          <div
            v-for="(logo, index) in logos"
            :key="'left-' + index"
            class="logo-item mx-8"
          >
            <img
              :src="logo"
              alt="Company Logo"
              class="w-32 h-32 object-contain"
            />
          </div>

          <!-- Duplicate the logos for seamless scrolling -->
          <div
            v-for="(logo, index) in logos"
            :key="'right-' + index"
            class="logo-item mx-8"
          >
            <img
              :src="logo"
              alt="Company Logo"
              class="w-32 h-32 object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      logos: [
        require("@/assets/CheckATrade-2.png"),
        require("@/assets/city-guilds.png"),
        require("@/assets/nico.png"),
        require("@/assets/nap.webp"),
        require("@/assets/nico.png"),
      ],
    };
  },
};
</script>

<style scoped>
/* Styling for the logo slider */
.container {
  max-width: 1200px;
}

.text-my-gray {
  color: #333; /* Example color for gray text, adjust as needed */
}

/* The slider container */
.relative {
  overflow: hidden; /* Ensures that logos outside the container won't show */
}

.logos-slider {
  display: flex;
  width: 100%;
  animation: slide 30s linear infinite; /* Infinite scrolling animation */
}

/* Styling for each logo item */
.logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
}

/* Keyframe animation for infinite scrolling */
@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      -50%
    ); /* Adjust to half of the total logo width for smooth transition */
  }
}

/* Smooth transition effect when hovering over logos (optional) */
.logo-item img {
  transition: transform 0.3s ease;
}

.logo-item:hover img {
  transform: scale(1.1); /* Slight zoom effect when hovered */
}
</style>
