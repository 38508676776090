import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faBinoculars } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faClock,
  faEnvelope,
  faLocationDot,
  faMapMarkerAlt, // Import the icon
} from "@fortawesome/free-solid-svg-icons";
import App from "@/App.vue";
import "@/assets/tailwind.css";
import AOS from "aos";
import "aos/dist/aos.css";
import router from "@/router";
import VueCarousel from "vue-carousel";

// Initialize AOS
AOS.init({
  once: true,
});

// Set up FontAwesome
library.add(
  faFacebook,
  faInstagram,
  faClock,
  faWhatsapp,
  faPhone,
  faBars,
  faEnvelope,
  faLocationDot,
  faMapMarkerAlt, // Add the icon here
  faBars,
  faChevronDown,
  faBinoculars
);

const app = createApp(App).use();

// Register plugins
app.use(router);
app.use(VueCarousel);

// Register FontAwesome component globally
app.component("font-awesome-icon", FontAwesomeIcon);

app.mount("#app");
