<template>
  <div class="pt-24">
    <div class="calendly-container">
      <!-- Calendly Inline Widget -->
      <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/euleadelectrical-info/30min"
        style="width: 100%; max-width: full; height: 800px"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendlyWidget",
  mounted() {
    this.loadCalendlyScript();
  },
  methods: {
    loadCalendlyScript() {
      const scriptId = "calendly-widget-script";
      // Prevent duplicate script tags
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.id = scriptId;
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
      }
    },
  },
};
</script>

<style scoped>
/* Center and style the widget container */
.calendly-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9; /* Light background for better contrast */
  min-height: 100vh; /* Ensures full height of the viewport */
}

.calendly-inline-widget {
  border: 1px solid #ddd; /* Add a subtle border for better visibility */
  border-radius: 10px; /* Round the edges of the widget */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for a modern look */
}
</style>
