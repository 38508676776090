<template>
  <div class="bg-gray-50">
    <!-- Header Section -->
    <header
      class="relative bg-cover bg-center h-64 sm:h-80 md:h-96 lg:h-[500px] flex items-center justify-center"
      data-aos="zoom-in"
      data-aos-duration="1000"
      :style="{
        backgroundImage: `url(${require('@/assets/contactus.jpeg')})`,
      }"
    >
      <div class="absolute inset-0 bg-gray-900 bg-opacity-0"></div>
      <div class="relative z-10 text-center text-white px-4 mt-auto">
        <h1 class="text-4xl sm:text-5xl md:text-6xl font-bold">
          Connect With Us
        </h1>
        <p class="mt-5 text-xs sm:text-sm">
          <router-link to="/"> <span>Home</span></router-link> /
          <router-link to="/contactus"> <span>Contact Us</span></router-link>
        </p>
      </div>
    </header>

    <contact-section class="pb-24 px-4 sm:px-6 md:px-8" />

    <!-- Updated Call Button with Animation -->
    <div class="flex justify-center my-6">
      <a href="tel:+44 7876 612498">
        <button
          class="call-button flex items-center px-4 py-2 sm:px-6 sm:py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-orange-600 transition-all transform duration-300"
        >
          <font-awesome-icon class="pr-2" :icon="['fas', 'phone']" />
          Call Us Now
        </button>
      </a>
    </div>

    <!-- Map Section -->
    <div class="h-[300px] sm:h-[400px] md:h-[500px] w-full">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12434.898663059296!2d-0.0940609!3d51.358305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTEuMzU4MzA1MDM1MjA3NTcsIC0wLjA5NDA2MDk0OTMwMjkzODIy!5e0!3m2!1sen!2sus!4v1696575296784!5m2!1sen!2sus"
        style="border: 0"
        class="w-full h-full"
        data-aos="fade-in"
        data-aos-duration="3000"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import ContactSection from "@/components/ContactSection/ContactSection.vue";

export default {
  components: { ContactSection },
  setup() {
    const form = reactive({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });

    const handleSubmit = () => {
      console.log("Form submitted:", form);
      alert("Your message has been sent!");
    };

    return { form, handleSubmit };
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Call button animation */
.call-button {
  animation: pulse 1.5s infinite;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
}

/* Hover effects */
.call-button:hover {
  animation: none; /* Stop animation on hover */
  transform: scale(1.05); /* Slightly scale up */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Shadow effect */
  background-color: #f97316; /* Brighter orange for hover */
}

/* Focus effects */
.call-button:focus {
  outline: none;
  box-shadow: 0 0 12px rgba(255, 165, 0, 0.5); /* Focus glow effect */
}

/* Animation keyframe for pulsing effect */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
