<template>
  <div>
    <!-- Preloader -->
    <div
      class="fixed inset-0 bg-white flex flex-col items-center justify-center z-50 transition-opacity duration-500"
      :class="{ 'opacity-0 pointer-events-none': !loading }"
    >
      <!-- Spinning Circle -->
      <div
        class="animate-spin rounded-full preloader-circle border-t-4 border-my-gray mb-4"
      ></div>

      <!-- Fade-In and Fade-Out Text -->
      <div class="preloader-text">
        {{ companyName }}
      </div>
    </div>

    <!-- Application Layout -->
    <div v-show="!loading">
      <LayOut />
    </div>
  </div>
</template>

<script>
import LayOut from "@/components/LayOut/LayOut.vue";

export default {
  components: {
    LayOut,
  },
  data() {
    return {
      loading: true, // Preloader visibility state
      companyName: "EULEADELECTRICAL", // Company name to display
    };
  },
  mounted() {
    this.waitForImagesToLoad();
  },
  methods: {
    waitForImagesToLoad() {
      const images = Array.from(document.querySelectorAll("img"));
      const imagePromises = images.map((img) => {
        return new Promise((resolve) => {
          if (img.complete) {
            resolve(); // Image already loaded
          } else {
            img.addEventListener("load", resolve);
            img.addEventListener("error", resolve); // Resolve even if the image fails to load
          }
        });
      });

      Promise.all(imagePromises).then(() => {
        // All images are loaded
        setTimeout(() => {
          this.loading = false; // Hide the preloader
        }, 500); // Optional delay for smoother transition
      });
    },
  },
};
</script>

<style scoped>
/* Preloader Circle */
.preloader-circle {
  height: 4rem; /* Default size */
  width: 4rem; /* Default size */
}

/* Preloader Text */
.preloader-text {
  font-size: 2.5rem; /* Default size */
  font-weight: 300;
  color: var(--my-dark);
  animation: fade 1.5s infinite;
  text-align: center;
}

/* Fade-in and fade-out animation */
@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Responsive Styles */
@media (min-width: 768px) {
  .preloader-circle {
    height: 6rem; /* Larger circle for tablets and above */
    width: 6rem;
  }
  .preloader-text {
    font-size: 3.5rem; /* Larger text size for tablets */
  }
}

@media (min-width: 1024px) {
  .preloader-circle {
    height: 8rem; /* Larger circle for desktops */
    width: 8rem;
  }
  .preloader-text {
    font-size: 4rem; /* Larger text size for desktops */
  }
}

/* Preloader fade-out */
.opacity-0 {
  opacity: 0;
}
.pointer-events-none {
  pointer-events: none;
}
</style>
