<template><HeroPage /></template>

<script>
import HeroPage from "@/components/HeroPage/HeroPage.vue";

// @ is an alias to /src

export default {
  name: "HomeView",
  components: { HeroPage },
};
</script>
