<template>
  <header
    class="bg-white sticky top-0 z-50 px-4 py-5 text-gray-600 flex items-center justify-between md:px-2"
  >
    <!-- Brand/Title -->
    <div>
      <router-link to="/" class="text-6xl font-bold">
        <img :src="require('@/assets/companylogo.png')" alt="Logo" />
      </router-link>
    </div>

    <!-- Navigation for larger screens -->
    <div class="flex-1 flex justify-center">
      <nav class="hidden md:flex font-semibold text-xl space-x-8 relative">
        <!-- Standard links -->
        <router-link to="/" class="hover:text-blue-500">Home</router-link>
        <router-link to="/about" class="hover:text-blue-500">About</router-link>

        <!-- Dropdown-enabled "Services" link -->
        <div class="relative group">
          <div class="flex items-center space-x-1 cursor-pointer">
            <router-link
              to="/ourservices"
              class="hover:text-blue-500 flex items-center"
            >
              Services
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                class="ml-2 text-gray-500 transition-transform duration-200 group-hover:rotate-180"
              />
            </router-link>
          </div>
          <ul
            class="absolute hidden group-hover:flex flex-col bg-white shadow-lg rounded-lg py-2 w-40 transform opacity-0 scale-95 transition duration-200 ease-out group-hover:opacity-100 group-hover:scale-100"
          >
            <li>
              <router-link
                to="/commercial"
                class="block px-4 py-2 hover:bg-blue-100"
                >Commercial
              </router-link>
            </li>
            <li>
              <router-link
                to="/residentialservice"
                class="block px-4 py-2 hover:bg-blue-100"
                >Residential</router-link
              >
            </li>
            <li>
              <router-link
                to="/evservice"
                class="block px-4 py-2 hover:bg-blue-100"
                >EVs
              </router-link>
            </li>
          </ul>
        </div>

        <router-link to="/contactus" class="hover:text-blue-500"
          >Contacts</router-link
        >
        <router-link to="/booking" class="hover:text-blue-500"
          >Appointment</router-link
        >
      </nav>
    </div>

    <!-- Toggle Button for smaller screens -->
    <div class="md:hidden">
      <button @click="toggleMenu" class="text-gray-600 hover:text-blue-500">
        <font-awesome-icon :icon="['fas', 'bars']" />
      </button>
    </div>
  </header>

  <!-- Navigation for smaller screens, toggled by the button -->
  <nav
    v-if="menuOpen"
    class="flex flex-col font-light space-y-6 px-4 py-2 bg-white"
  >
    <router-link to="/" class="hover:text-blue-500" @click="toggleMenu"
      >Home</router-link
    >
    <router-link to="/about" class="hover:text-blue-500" @click="toggleMenu"
      >About</router-link
    >

    <!-- Mobile "Services" Dropdown -->
    <div class="relative">
      <div
        @click="toggleServices"
        class="flex items-center space-x-1 cursor-pointer"
      >
        <router-link
          to="/ourservices"
          class="hover:text-blue-500 flex items-center"
        >
          Services
          <font-awesome-icon
            :icon="['fas', 'chevron-down']"
            class="ml-2 text-gray-500 transition-transform duration-200"
          />
        </router-link>
      </div>
      <ul
        v-show="servicesOpen"
        class="flex-col bg-white shadow-lg rounded-lg py-2 w-40"
      >
        <li>
          <router-link
            to="/commercial"
            class="block px-4 py-2 hover:bg-blue-100"
            @click="toggleMenu"
            >Commercial
          </router-link>
        </li>
        <li>
          <router-link
            to="/residentialservice"
            class="block px-4 py-2 hover:bg-blue-100"
            @click="toggleMenu"
            >Residential</router-link
          >
        </li>
        <li>
          <router-link
            to="/evservice"
            class="block px-4 py-2 hover:bg-blue-100"
            @click="toggleMenu"
            >EVs
          </router-link>
        </li>
      </ul>
    </div>

    <router-link to="/contactus" class="hover:text-blue-500" @click="toggleMenu"
      >Contacts</router-link
    >
    <router-link to="/booking" class="hover:text-blue-500" @click="toggleMenu"
      >Appointment</router-link
    >
  </nav>
</template>

<script>
export default {
  name: "ResponsiveNav",
  data() {
    return {
      menuOpen: false, // Menu is initially closed
      servicesOpen: false, // Track whether the services dropdown is open
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen; // Toggle the menu visibility
    },
    toggleServices() {
      this.servicesOpen = !this.servicesOpen; // Toggle the "Services" dropdown visibility
    },
  },
};
</script>
