<template>
  <image-carousel /><our-service /><guaranteed-page /><infinite-slider /><contact-section />
</template>

<script>
import ImageCarousel from "@/components/ImageCarousel/ImageCarousel.vue";
import ContactSection from "@/components/ContactSection/ContactSection.vue";
import GuaranteedPage from "@/components/HeroPage/GuaranteedPage.vue";
import OurService from "@/components/OurService/OurService.vue";
import InfiniteSlider from "@/components/ImageCarousel/InfiniteSlider.vue";
export default {
  components: {
    ImageCarousel,
    ContactSection,
    GuaranteedPage,
    InfiniteSlider,

    OurService,
  },
};
</script>

<style></style>
