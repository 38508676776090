<template>
  <div class="p-10 bg-gray-50">
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
      <!-- Contact Information -->
      <div
        class="space-y-6 lg:ml-24"
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <h2 class="text-3xl lg:text-5xl font-normal py-4">Let's Connect</h2>
        <div
          class="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 pt-3 group"
        >
          <span class="text-yellow-500 text-2xl">
            <img
              class="contact-img"
              :src="require('@/assets/contact-img.png')"
              alt="Contact"
            />
          </span>
          <div class="contact-text">
            <h3 class="text-2xl lg:text-3xl font-serif">Contact</h3>
            <div class="text-sm lg:text-base">
              <a
                href="mailto:info@euleadelectrical.co.uk"
                class="flex items-center"
              >
                <font-awesome-icon class="pr-1" :icon="['fas', 'envelope']" />
                info@euleadelectrical.co.uk
              </a>
              <a href="tel:+44 7876 612498" class="flex items-center">
                <font-awesome-icon class="pr-1" :icon="['fas', 'phone']" />
                +44 7876-612498
              </a>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 pt-3 group"
        >
          <span class="text-yellow-500 text-2xl">
            <img
              class="contact-img"
              :src="require('@/assets/office-img.png')"
              alt="Office"
            />
          </span>
          <div class="contact-text">
            <h3 class="text-2xl lg:text-3xl font-serif">Our Office</h3>
            <a href="#map" class="flex items-center">
              <font-awesome-icon class="pr-1" :icon="['fas', 'location-dot']" />
              25 Selsdon Avenue South Croydon CR2 6P
            </a>
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-start space-y-4 sm:space-y-0 sm:space-x-4 pt-3 group"
        >
          <span class="text-yellow-500 text-2xl">
            <img
              class="contact-img"
              :src="require('@/assets/workinghrs-img.png')"
              alt="Working Hours"
            />
          </span>
          <div class="contact-text">
            <h3 class="text-2xl lg:text-3xl font-serif">Working Hour</h3>
            <div class="flex items-center space-x-2">
              <font-awesome-icon :icon="['fas', 'clock']" />
              <span>Mon-Fri: 9am-5pm</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Contact Form -->
      <div class="pt-10">
        <form
          class="form space-y-4 bg-white p-4 sm:p-6 rounded shadow-md shadow-red-800"
          action="https://formspree.io/f/mjkvdrqn"
          method="POST"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            required
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            required
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <input
            type="tel"
            name="phone"
            placeholder="Your Phone"
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <textarea
            name="message"
            placeholder="Your Message"
            rows="4"
            required
            class="w-full border border-gray-300 rounded p-3 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          ></textarea>
          <button
            type="submit"
            class="w-full bg-orange-500 text-white font-semibold py-2 px-4 rounded hover:bg-orange-600"
          >
            Submit Now
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
};
</script>

<style>
/* Animation for form */
.form {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  will-change: transform, box-shadow;
}

.form:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 12px 24px rgba(255, 0, 0, 0.3); /* Enhances the shadow on hover */
}

/* Animation for images */
.contact-img {
  transition: transform 0.3s ease, filter 0.3s ease;
  will-change: transform, filter;
}

.contact-img:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

/* Animation for text beside images */
.contact-text {
  transition: transform 0.3s ease, color 0.3s ease;
  will-change: transform, color;
}

.group:hover .contact-text {
  transform: translateX(10px); /* Moves the text slightly to the right */
}

/* Accessibility and responsiveness */
@media (hover: hover) {
  .form:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 12px 24px rgba(255, 0, 0, 0.3);
  }
  .contact-img:hover {
    transform: scale(1.1);
    filter: brightness(1.2);
  }
  .group:hover .contact-text {
    transform: translateX(10px);
  }
}

.form:focus-within {
  box-shadow: 0 0 12px rgba(255, 165, 0, 0.5);
}

.contact-img:focus {
  outline: 2px solid #ff0;
  outline-offset: 4px;
}
</style>
