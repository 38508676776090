<template>
  <section class="py-16 bg-gray-50">
    <div class="container mx-auto text-center">
      <h2 class="text-3xl font-bold mb-4 text-my-gray">Our Services</h2>
      <p class="text-my-gray mb-8 pb-7">
        We specialize in delivering reliable and professional electrical
        solutions tailored to your needs.
      </p>
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div
          v-for="(service, index) in services"
          :key="index"
          class="relative bg-white shadow-lg shadow-gray-300 rounded-lg pt-16 pb-6 px-6"
        >
          <!-- Image wrapped with a link -->
          <div
            class="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center"
          >
            <a :href="service.url" target="_blank" rel="noopener noreferrer">
              <img :src="service.icon" :alt="service.title" class="w-8 h-8" />
            </a>
          </div>

          <!-- Card Content -->
          <h3 class="text-xl font-semibold mt-8">{{ service.title }}</h3>
          <p class="text-my-gray mt-4 mb-6">{{ service.description }}</p>

          <!-- <button
            class="px-4 py-2 text-blue-600 border border-blue-600 rounded hover:bg-blue-600 hover:text-white"
          >
            Read More
          </button> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "Electrical Systems Maintenance",
          icon: require("@/assets/electrical-engineering.png"),
          description:
            "We offer comprehensive maintenance for all your electrical systems.",
          url: "https://www.flaticon.com/free-icons/engineering",
        },
        {
          title: "Security Systems and CCTV",
          icon: require("@/assets/cctv.png"),
          description:
            "Keep your premises secure with our advanced security solutions.",
          url: "https://www.flaticon.com/free-icons/cctv",
        },
        {
          title: "Electric Vehicle Support Services",
          icon: require("@/assets/evcharging.png"),
          description:
            "Expert support for EV charging and related electrical systems.",
          url: "https://www.flaticon.com/free-icons/electric-vehicle",
        },
        {
          title: "Rewiring",
          icon: require("@/assets/wiring.png"),
          description:
            "Professional rewiring services for homes and businesses.",
          url: "https://www.flaticon.com/free-icons/wiring",
        },
        {
          title: "Panels Changes",
          icon: require("@/assets/solar.png"),
          description:
            "Upgrade your electrical panels for better efficiency and safety.",
          url: "https://www.flaticon.com/free-icons/solar-panel",
        },
        {
          title: "Trouble Shooting",
          icon: require("@/assets/troubleshooting.png"),
          description: "Quick diagnosis and resolution of electrical issues.",
          url: "https://www.flaticon.com/free-icons/solar-panel",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* ... (previous styles remain the same) ... */
</style>

<style scoped>
.container {
  max-width: 1200px;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

* {
  font-family: "Inter", sans-serif;
}
/* Card hover effects */
.relative {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.relative:hover {
  transform: translateY(-10px) scale(1.05); /* Lift the card slightly and scale it up */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add a larger shadow on hover */
}

/* Button hover effect */
button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: #2563eb; /* Darker blue */
  color: white; /* Text color change */
}

/* Optional: Adding a smoother hover transition for the icon */
img {
  transition: transform 0.3s ease;
}

.relative:hover img {
  transform: rotate(
    15deg
  ); /* Rotate the icon slightly when the card is hovered */
}

/* Adjust the row gap between cards */
.grid {
  row-gap: 2rem;
}

/* Target the first row of cards and add a bottom margin */
.grid > .relative:nth-child(1),
.grid > .relative:nth-child(2),
.grid > .relative:nth-child(3) {
  margin-bottom: 2rem;
}
</style>
