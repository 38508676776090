<template>
  <header
    class="relative bg-cover bg-center h-64 sm:h-80 md:h-96 lg:h-[500px] flex items-center justify-center"
    data-aos="zoom-in"
    data-aos-duration="1000"
    :style="{
      backgroundImage: `url(${require('@/assets/ourservices.jpeg')})`,
    }"
  >
    <div class="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
    <div class="relative z-10 text-center text-white px-4 mt-auto">
      <h1 class="text-4xl sm:text-5xl md:text-6xl font-bold">Our Services</h1>
      <p class="mt-5 text-xs sm:text-sm pb-10">
        <router-link to="/"> <span>Home</span></router-link> /
        <router-link to="/ourservices"> <span>Our Services</span></router-link>
      </p>
    </div>
  </header>

  <section class="bg-gray-50 py-12">
    <div class="container mx-auto px-4">
      <!-- Header Section -->
      <div class="text-center mb-8"></div>

      <!-- Service Cards -->
      <div
        class="grid grid-cols-1 md:grid-cols-3 gap-6"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <!-- Commercial Services -->
        <div class="relative overflow-hidden rounded-lg shadow-lg group">
          <img
            :src="require('@/assets/commercialservices.jpeg')"
            alt="Commercial Services"
            class="w-full h-96 object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div
            class="absolute inset-0 bg-black bg-opacity-0 flex items-end p-4"
          >
            <div>
              <h4 class="text-white text-xl font-bold">Commercial Services</h4>
              <button
                class="mt-2 px-4 py-2 bg-white text-gray-800 text-sm font-semibold rounded hover:bg-gray-100"
              >
                <router-link to="/commercial"> Learn More</router-link>
              </button>
            </div>
          </div>
        </div>

        <!-- Residential Services -->
        <div class="relative overflow-hidden rounded-lg shadow-lg group">
          <img
            :src="require('@/assets/residential1.jpeg')"
            alt="Residential Services"
            class="w-full h-96 object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div
            class="absolute inset-0 bg-black bg-opacity-0 flex items-end p-4"
          >
            <div>
              <h4 class="text-white text-xl font-bold">Residential Services</h4>
              <button
                class="mt-2 px-4 py-2 bg-white text-gray-800 text-sm font-semibold rounded hover:bg-gray-100"
              >
                <router-link to="/residentialservice"> Learn More</router-link>
              </button>
            </div>
          </div>
        </div>

        <!-- Industrial Services -->
        <div class="relative overflow-hidden rounded-lg shadow-lg group">
          <img
            :src="require('@/assets/Evservices.jpeg')"
            alt="EV Services"
            class="w-full h-96 object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div
            class="absolute inset-0 bg-black bg-opacity-0 flex items-end p-4"
          >
            <div>
              <h4 class="text-white text-xl font-bold">EV Services</h4>
              <button
                class="mt-2 px-4 py-2 bg-white text-gray-800 text-sm font-semibold rounded hover:bg-gray-100"
              >
                <router-link to="/evservice"> Learn More</router-link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EvService",
};
</script>

<style>
/* Optional: Custom styles if needed */
</style>
