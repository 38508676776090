<template>
  <div class="shapes-container">
    <div
      v-for="shape in shapes"
      :key="shape.id"
      class="shape"
      :class="shape.type"
      :style="{
        left: `${shape.x}px`,
        top: `${shape.y}px`,
        width: `${shape.size}px`,
        height: `${shape.size}px`,
        opacity: shape.opacity,
        transform: `rotate(${shape.rotation}deg)`,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PhysicsShapes",
  data() {
    return {
      shapes: [],
      containerWidth: 0,
      containerHeight: 0,
      animationFrameId: null,
      randomSeed: Date.now(), // Add consistent seed for random generation
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeComponent();
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    this.cleanupAnimation();
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    initializeComponent() {
      this.handleResize(); // Calculate initial container dimensions
      this.initShapes();
      this.startAnimation();
    },
    handleResize() {
      // Defensive check for container
      if (!this.$el || !this.$el.parentElement) return;

      const container = this.$el.parentElement;
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;

      // Only update if dimensions have actually changed
      if (
        newWidth !== this.containerWidth ||
        newHeight !== this.containerHeight
      ) {
        this.containerWidth = newWidth;
        this.containerHeight = newHeight;

        // Adjust shapes to stay precisely within bounds
        this.shapes = this.shapes.map((shape) => ({
          ...shape,
          x: Math.max(0, Math.min(shape.x, this.containerWidth - shape.size)),
          y: Math.max(0, Math.min(shape.y, this.containerHeight - shape.size)),
        }));
      }
    },
    initShapes() {
      // Ensure container is available
      if (!this.$el || !this.$el.parentElement) return;

      const container = this.$el.parentElement;
      this.containerWidth = container.clientWidth;
      this.containerHeight = container.clientHeight;

      const shapeTypes = [
        "circle",
        "square",
        "triangle",
        "pentagon",
        "star",
        "hexagon",
        "diamond",
      ];

      // Use seeded random generation for more consistent randomness
      const seededRandom = (seed) => {
        const x = Math.sin(seed) * 10000;
        return x - Math.floor(x);
      };

      this.shapes = Array.from({ length: 20 }, (_, index) => {
        // Use the index and seed to create more predictable random values
        const randomValue = (val) =>
          seededRandom(this.randomSeed + val + index);

        const size = 20 + randomValue(0) * 40;

        // Constrain initial positions more carefully
        const x = Math.max(
          0,
          Math.min(
            randomValue(1) * (this.containerWidth - size),
            this.containerWidth - size
          )
        );

        const y = Math.max(
          0,
          Math.min(
            randomValue(2) * (this.containerHeight - size),
            this.containerHeight - size
          )
        );

        return {
          id: index,
          type: shapeTypes[Math.floor(randomValue(3) * shapeTypes.length)],
          x,
          y,
          vx: (randomValue(4) - 0.5) * 5,
          vy: (randomValue(5) - 0.5) * 5,
          size,
          opacity: 0.1 + randomValue(6) * 0.4,
          rotation: randomValue(7) * 360,
        };
      });
    },
    updateShapes() {
      this.shapes = this.shapes.map((shape) => {
        let newX = shape.x + shape.vx;
        let newY = shape.y + shape.vy;
        let newVx = shape.vx;
        let newVy = shape.vy;

        // Bounce off container walls with some energy loss
        if (newX <= 0 || newX + shape.size >= this.containerWidth) {
          newVx = -shape.vx * 0.95;
          newX = newX <= 0 ? 0 : this.containerWidth - shape.size;
        }

        if (newY <= 0 || newY + shape.size >= this.containerHeight) {
          newVy = -shape.vy * 0.95;
          newY = newY <= 0 ? 0 : this.containerHeight - shape.size;
        }

        const clampVelocity = (velocity, min) =>
          Math.abs(velocity) < min ? (velocity < 0 ? -min : min) : velocity;

        newVx = clampVelocity(newVx, 0.5);
        newVy = clampVelocity(newVy, 0.5);

        return {
          ...shape,
          x: newX,
          y: newY,
          vx: newVx,
          vy: newVy,
          rotation: (shape.rotation + 1) % 360,
        };
      });
    },
    startAnimation() {
      this.cleanupAnimation(); // Ensure previous animation is stopped

      const animate = () => {
        this.updateShapes();
        this.animationFrameId = requestAnimationFrame(animate);
      };
      animate();
    },
    cleanupAnimation() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null;
      }
    },
  },
};
</script>

<style scoped>
.shapes-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

.shape {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
}

.shape.circle {
  border-radius: 50%;
}

.shape.square {
  background-color: rgba(255, 255, 255, 0.2);
}

.shape.triangle {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-bottom: 50px solid rgba(255, 255, 255, 0.2);
}

.shape.pentagon {
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.shape.star {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.shape.hexagon {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.shape.diamond {
  transform: rotate(45deg);
}
</style>
