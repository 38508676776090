<template>
  <header
    class="relative bg-cover bg-center h-64 sm:h-80 md:h-96 lg:h-[500px] flex items-center justify-center"
    data-aos="zoom-in"
    data-aos-duration="1000"
    :style="{
      backgroundImage: `url(${require('@/assets/evservice.jpeg')})`,
    }"
  >
    <div class="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
    <div class="relative z-10 text-center text-white px-4 mt-auto">
      <h1 class="text-4xl sm:text-5xl md:text-6xl font-bold">EV Services</h1>
      <p class="mt-5 text-xs sm:text-sm pb-10">
        <router-link to="/"> <span>Home</span></router-link> /
        <router-link to="/ourservices"> <span>Our Services</span></router-link>
      </p>
    </div>
  </header>
  <div
    class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
    data-aos="fade-up"
    data-aos-duration="2000"
  >
    <div
      v-for="(service, index) in services"
      :key="index"
      class="relative bg-white shadow-lg shadow-gray-300 rounded-lg pt-16 pb-6 px-6"
    >
      <!-- Image wrapped with a link -->
      <div
        class="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-blue-100 rounded-full w-16 h-16 flex items-center justify-center"
      >
        <a :href="service.url" target="_blank" rel="noopener noreferrer">
          <img :src="service.icon" :alt="service.title" class="w-8 h-8" />
        </a>
      </div>

      <!-- Card Content -->
      <h3 class="text-xl font-semibold mt-8">{{ service.title }}</h3>
      <p class="text-my-gray mt-4 mb-6">{{ service.description }}</p>

      <!-- <button
            class="px-4 py-2 text-blue-600 border border-blue-600 rounded hover:bg-blue-600 hover:text-white"
          >
            Read More
          </button> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          title: "New Installation for Charging point",
          icon: require("@/assets/evs.png"),
          description:
            "We offer comprehensive new installation services for charging points, ensuring safe and efficient power for your electric vehicle.",
          url: "https://www.flaticon.com/free-icons/engineering",
        },
        {
          title: "Reallocation of Charging Point",
          icon: require("@/assets/evreallocation.png"),
          description:
            "We provide efficient reallocation services for your charging point, ensuring optimal placement and seamless functionality.",
          url: "https://www.flaticon.com/free-icons/cctv",
        },
        {
          title: "Charging Point Upgrade",
          icon: require("@/assets/evupgrade.png"),
          description:
            "We offer expert upgrades to your existing charging point, enhancing its efficiency and compatibility with the latest electric vehicle technology.",
          url: "https://www.flaticon.com/free-icons/electric-vehicle",
        },
      ],
    };
  },
};
</script>

<style scoped>
/* ... (previous styles remain the same) ... */
</style>

<style scoped>
.container {
  max-width: 1200px;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

* {
  font-family: "Inter", sans-serif;
}
/* Card hover effects */
.relative {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.relative:hover {
  transform: translateY(-10px) scale(1.05); /* Lift the card slightly and scale it up */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add a larger shadow on hover */
}

/* Button hover effect */
button {
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: #2563eb; /* Darker blue */
  color: white; /* Text color change */
}

/* Optional: Adding a smoother hover transition for the icon */
img {
  transition: transform 0.3s ease;
}

.relative:hover img {
  transform: rotate(
    15deg
  ); /* Rotate the icon slightly when the card is hovered */
}

/* Adjust the row gap between cards */
.grid {
  row-gap: 2rem;
}

/* Target the first row of cards and add a bottom margin */
.grid > .relative:nth-child(1),
.grid > .relative:nth-child(2),
.grid > .relative:nth-child(3) {
  margin-bottom: 2rem;
}
</style>
