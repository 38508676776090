<template><main-nav></main-nav><router-view /><main-footer /></template>

<script>
import MainNav from "@/components/MainNav/MainNav.vue";

import MainFooter from "@/components/Footer/MainFooter.vue";

export default {
  components: {
    MainNav,

    MainFooter,
  },
};
</script>

<style></style>
