<template>
  <div
    class="flex flex-col md:flex-row items-center justify-between gap-8 px-6 md:px-20 py-12"
  >
    <!-- Text Section -->
    <div
      class="max-w-lg text-center md:text-left"
      data-aos="fade-right"
      data-aos-duration="2000"
    >
      <h1
        class="mt-4 text-3xl md:text-4xl font-extrabold text-gray-900 leading-snug"
      >
        Powering Your Home with Expert Electrical Services
      </h1>
      <p class="mt-4 text-gray-600">
        Whether it's a quick fix or a complete wiring overhaul, we provide
        reliable, safe, and efficient electrical solutions. Trust us to keep
        your home running smoothly and safely.
      </p>

      <button
        class="call-button mt-6 px-4 py-2 sm:px-6 sm:py-3 bg-blue-600 text-sm sm:text-base text-white font-mono rounded-md hover:bg-blue-700"
      >
        Call Us
        <span>
          <a href="tel:+44 7876 612498" class="flex items-center">
            <font-awesome-icon class="pr-1" :icon="['fas', 'phone']" />
            +44 7876-612498
          </a>
        </span>
      </button>
    </div>

    <!-- Image Section -->
    <div class="max-w-md md:max-w-lg w-full">
      <img
        :src="require('@/assets/eulead.png')"
        alt="About Us Image"
        class="w-full h-auto"
        data-aos="fade-left"
        data-aos-duration="2000"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeRepair",
};
</script>

<style scoped>
@media (max-width: 640px) {
  .call-button {
    padding: 0.5rem 1rem; /* Smaller padding */
    font-size: 0.875rem; /* Smaller font size */
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(0, 0, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(0, 0, 255, 0.7);
  }
}

.call-button {
  animation: pulse 1.5s infinite;
  transition: transform 0.3s ease-in-out;
}

.call-button:hover {
  animation: none; /* Stop animation on hover */
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(0, 0, 255, 0.9);
}
</style>
