<template>
  <div class="fixed top-0 left-0 right-0 z-50 w-full">
    <!-- Top header -->
    <header
      class="hidden sm:block w-full bg-logo-color mx-auto py-3 px-4 sm:px-10 sm:text-md font-normal"
    >
      <div
        class="flex flex-col sm:flex-row justify-center sm:justify-between text-white"
      >
        <!-- Contact Information -->
        <div
          class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4"
        >
          <a href="tel:+44 7876 612498" class="flex items-center">
            <font-awesome-icon class="pr-1" :icon="['fas', 'phone']" />
            +44 7876-612498
          </a>
          <a
            href="mailto:info@euleadelectrical.co.uk"
            class="flex items-center"
          >
            <font-awesome-icon class="pr-1" :icon="['fas', 'envelope']" />
            info@euleadelectrical.co.uk
          </a>
          <a href="#map" class="flex items-center">
            <font-awesome-icon class="pr-1" :icon="['fas', 'location-dot']" />
            25 Selsdon Avenue South Croydon CR2 6P
          </a>
        </div>

        <!-- Social Media Icons (right side) -->
        <div class="flex space-x-4 items-center">
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-red-700"
          >
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a
            href="/"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-yellow-300"
          >
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a
            href="https://wa.me/+447876612498"
            target="_blank"
            rel="noopener noreferrer"
            class="hover:text-green-600"
          >
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
          </a>
        </div>
      </div>
    </header>
    <!-- Second nav -->
    <second-nav class="mt-0"></second-nav>
  </div>
  <!-- Add a spacer to prevent content from hiding behind the fixed header -->
  <div class="h-[calc(var(--header-height) + var(--second-nav-height))]"></div>
</template>

<script>
import SecondNav from "@/components/MainNav/SecondNav.vue";
export default {
  name: "MainNav",
  components: { SecondNav },
};
</script>

<style>
:root {
  --header-height: 64px; /* Adjust this value based on your header's height */
  --second-nav-height: 60px; /* Adjust this value based on your second nav's height */
}

header {
  margin-bottom: 0; /* Ensures no space between header and second-nav */
}

.second-nav {
  margin-top: 0; /* Avoid spacing above second nav */
}

.fixed {
  position: fixed;
  background-color: inherit;
}

.z-50 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
