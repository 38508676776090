<template>
  <div class="relative w-full md:pt-28">
    <carousel
      :items-to-show="1"
      :autoplay="2000"
      :wrap-around="true"
      :infinite="true"
      :transition="1000"
      :mouseDrag="true"
      :touchDrag="true"
      ref="carousel"
      @slide-end="handleSlideEnd"
    >
      <slide v-for="(image, index) in images" :key="index">
        <div class="relative w-full h-[500px]">
          <img
            :src="getImage(image.url)"
            class="w-full h-full object-cover"
            :alt="`Slide ${index + 1}`"
          />
        </div>
      </slide>

      <template #addons>
        <pagination
          class="absolute bottom-4 left-1/2 transform -translate-x-1/2"
        >
          <template #addons>
            <div class="flex space-x-4">
              <button
                v-for="(_, index) in images"
                :key="index"
                @click="goToSlide(index)"
                :class="[
                  'w-6 h-6 rounded-full border-2 transition-colors duration-300 focus:outline-none',
                  currentImageId === images[index].id
                    ? 'bg-white border-white'
                    : 'bg-transparent border-gray-300',
                ]"
                :aria-label="`Go to slide ${index + 1}`"
              ></button>
            </div>
          </template>
        </pagination>
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "ImageCarousel",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      currentImageId: 0, // Store the ID of the current image
      images: [
        { id: 1, url: "caroo.jpg" },
        { id: 2, url: "solar.jpeg" },
        { id: 3, url: "CHARGING.jpeg" },
      ],
    };
  },
  methods: {
    getImage(imagePath) {
      return require(`@/assets/${imagePath}`);
    },
    prevSlide() {
      this.$refs.carousel.prev();
    },
    nextSlide() {
      this.$refs.carousel.next();
    },
    goToSlide(index) {
      this.$refs.carousel.slideTo(index);
      this.updateImageId(index);
    },
    handleSlideEnd(slide) {
      const image = this.images[slide.currentSlide];
      if (image) {
        this.currentImageId = image.id; // Update current image id on slide change
      } else {
        console.error("Slide data is not valid or out of bounds");
      }
    },
    updateImageId(index) {
      const image = this.images[index];
      if (image) {
        this.currentImageId = image.id;
      }
    },
  },
};
</script>

<style scoped>
.carousel__slide {
  padding: 0;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1);
}

/* Add this CSS to hide the navigation arrows on small screens */
@media (max-width: 768px) {
  .carousel__navigation button {
    display: none;
  }
}
</style>
