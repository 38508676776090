<template>
  <footer class="bg-logo-color text-white py-10 relative overflow-hidden">
    <moving-shapes />
    <div
      class="container mx-auto px-4 md:px-8 grid grid-cols-1 md:grid-cols-3 gap-8 relative z-10"
    >
      <!-- Brand Section -->
      <div data-aos="fade-up" data-aos-delay="0">
        <div class="flex items-center space-x-2">
          <div class="text-red-500">
            <!-- Icon -->
          </div>
          <span class="text-2xl font-semibold">EULEAD LTD</span>
        </div>
        <p class="text-white text leading-relaxed pt-4 pl-1">
          At Eulead Ltd, we provide top-notch electrical services with a focus
          on safety, reliability, and customer satisfaction. Our team of
          qualified electricians delivers expert solutions for both homes and
          businesses. Trust us to handle your electrical needs with
          professionalism and care.
        </p>
      </div>

      <!-- Links Section -->
      <div data-aos="fade-up" data-aos-delay="200">
        <h4 class="text-lg font-semibold mb-4">Link</h4>
        <ul class="space-y-2">
          <li>
            <router-link to="/" class="hover:text-my-gray transition">
              > Home</router-link
            >
          </li>
          <li>
            <router-link to="/contactus" class="hover:text-my-gray transition">
              > Contact Us</router-link
            >
          </li>

          <li>
            <router-link
              to="/ourservices"
              class="hover:text-my-gray transition"
            >
              > Services</router-link
            >
          </li>
          <li>
            <router-link to="/about" class="hover:text-my-gray transition">
              > About Us</router-link
            >
          </li>
        </ul>
      </div>

      <!-- Contact Section -->
      <div data-aos="fade-up" data-aos-delay="400">
        <h4 class="text-lg font-semibold mb-4">Contact Us</h4>
        <p class="text-white mb-2">
          <strong>Address:</strong>
          <a href="#map" class="flex items-center">
            <font-awesome-icon class="pr-1" :icon="['fas', 'location-dot']" />
            25 Selsdon Avenue South Croydon CR2 6P
          </a>
        </p>
        <p class="text-white mb-2">
          <strong>Phone:</strong>
          <a href="tel:+44 7876 612498" class="flex items-center">
            <font-awesome-icon class="pr-1" :icon="['fas', 'phone']" />
            +44 7876-612498
          </a>
        </p>
        <p class="text-white mb-4">
          <strong>Email:</strong>
          <a
            href="mailto:info@euleadelectrical.co.uk"
            class="flex items-center"
          >
            <font-awesome-icon class="pr-1" :icon="['fas', 'envelope']" />
            info@euleadelectrical.co.uk
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import MovingShapes from "@/components/Footer/MovingShapes.vue";
export default {
  name: "MainFooter",
  components: { MovingShapes },
};
</script>
